import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BsPlusCircle } from 'react-icons/bs';
import { showError } from '../../alerts';
import ImageZoom from '../../components/ImageZoom';
import TextIcon from '../../components/TextIcon';
import { useArray } from '../../hooks';
import { ITextArrayDataOptions } from '../../interfaces/common';
import { getControlData, loadFileToImageControl } from '../../utils';
import RelationshipImageEditor from './RelationshipImageEditor';

interface IRelationshipImageListProps {
	initialImages?: string[];
	className?: string;
	disabled?: boolean;
	onChange: (data: ITextArrayDataOptions) => void;
	isLoading?: boolean;
	// Тут inserting використовується для правильної реакції на копіювання зв'язку
	inserting: boolean;
}
const RelationshipImageList = ({
	initialImages,
	className,
	disabled,
	onChange,
	isLoading,
	inserting,
}: IRelationshipImageListProps) => {
	const refFile = useRef<HTMLInputElement>(null);
	const { data, setData, modified, toggleItem } = useArray(initialImages);
	const [zoomImgSrc, setZoomImgSrc] = useState<string>();

	useEffect(() => {
		if (!initialImages) return;
		setData(initialImages);
	}, [initialImages]); // eslint-disable-line

	useEffect(() => {
		if (inserting) setData([]);
	}, [inserting]); // eslint-disable-line

	useEffect(() => {
		onChange({ modified, selected: data });
	}, [modified, data]); // eslint-disable-line

	/**
	 * Нас в даному випадку цікавить натискання на зображенні, у відповідь на яке
	 * виводиться діалогове вікно з даним зображенням.
	 */
	const onListClick = useCallback(async (event: React.MouseEvent<HTMLDivElement>) => {
		const src = getControlData(event.target, '.img-zoom-able', 'src');
		setZoomImgSrc(src);
	}, []);

	const onZoomImageClose = useCallback(() => {
		setZoomImgSrc(undefined);
	}, []);

	const showSelectedImage = useCallback(async () => {
		try {
			const src = await loadFileToImageControl(refFile.current);
			if (src) setData([...data, src]);
		} catch (error) {
			showError(error as any);
		}
	}, [data, setData]);

	const onAddImageClick = useCallback(() => refFile.current?.click(), []);

	const onDelete = useCallback(
		(src: string) => {
			toggleItem(src);
		},
		[toggleItem]
	);

	return (
		<>
			<div className={classNames(className, 'd-flex gap-2 overflow-auto flex-shrink-0')} onClick={onListClick}>
				{data.map((src, index) => (
					<RelationshipImageEditor
						key={index}
						src={src}
						editable={!disabled}
						onDelete={onDelete}
						isLoading={isLoading}
					/>
				))}
				{!disabled && (
					<Button
						variant="outline-secondary"
						className="flex-shrink-0 rel-image__item"
						title="Додати до зв'язку зображення"
						onClick={onAddImageClick}
						disabled={isLoading}
					>
						<TextIcon Icon={BsPlusCircle} inline size="lg" className="mt-n1">
							Зображення
						</TextIcon>
					</Button>
				)}
			</div>
			{zoomImgSrc && <ImageZoom src={zoomImgSrc} onClose={onZoomImageClose} />}
			<input
				ref={refFile}
				type="file"
				id="relImageUpload"
				name="relImageUpload"
				accept=".png, .jpg, .jpeg, .svg, .webp"
				autoComplete="off"
				className="d-none"
				onChange={showSelectedImage}
			/>
		</>
	);
};

export default RelationshipImageList;
